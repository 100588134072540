<template>
  <div class="container">
   
    <div class="nav">
      <img src="../assets/index/logo.png" style="width: .6771rem;" alt="" @click="gotoHome" />
      <transition name="fade">
      <ul class="navBar" style="font-size: 0.0833rem;display: flex;">
        
        <li :data-path="item.path" :data-index="index" class="navs"
          :class="(a == index || suoyin == index) ? 'active' : ''" @click="getNavsList" v-for="(item, index) in navList"
          :key="index" @mouseenter="showBox(index)" @mouseleave="hideBox2(item.navsList)">
          <!--    -->
          {{ item.name }}
          <!-- li  对应的nav标签 -->
          <ol class="navList" v-show="item.id == a" v-if="item.navsList != 0" @mouseleave="hideBox">
            <li v-for="(b, i) in item.navsList" :data-index="index" :key="i" @click="toArea(b, i, index)"
              @mouseenter="changeliHover(i)" class="navsli">
              <img :src="b.imgUrl" alt="">
              <span :style="{ color: (actives == i || as == i) ? '#FF6A00' : '' }">{{ b.txt }}</span>
            </li>
          </ol>
        </li>
      </ul>
    </transition>
      <div class="tel">
        <p style="font-size: 0.1042rem;font-weight: bold;">
          18611858511
        </p>
        <!-- <p
          style="margin: 0 0.0625rem;  width: 1px;
            height: 0.16rem;
            background: rgba(34, 34, 34, 0.1);
          "
        ></p> -->
        <!-- <button
        @click="showForm"
          style="
            width: 0.4479rem;
            height: 0.1458rem;
            font-size: 0.0729rem;
            color: white;
            border-radius: 0.0208rem;
            background-image: linear-gradient(to right, #ff6a00, #ff8c1f);
            border: none;
          "
        >
          立即咨询
        </button> -->
      </div>
      <div class="menu-btn" @click="zhankai" :class="isShow ? 'actives' : ''">
          <span></span>
          <span></span>
          <span></span>
      </div>

      <!-- 手机上的菜单 -->
    <div class="moddileList" :class="isShow ? 'in' : ''">
      <ul>
        <li :data-path="item.path" :data-index="index" @click="getNavsList" v-for="(item, index) in navList"
          :key="index">
          <!--    -->
          {{ item.name }}
          <!-- li  对应的nav标签 -->
          <!-- <ol v-show="item.id == a" v-if="item.navsList != 0" @mouseleave="hideBox">
            <li v-for="(b, i) in item.navsList" :data-index="index" :key="i" @click="toArea(b, i, index)"
              @mouseenter="changeliHover(i)">
              <img :src="b.imgUrl" alt="">
              <span>{{ b.txt }}</span>
            </li>
          </ol> -->
        </li>
      </ul>
    </div>
      
    </div>
    
    <!-- 联系我们 -->
  </div>
</template>

<script>
import { navs } from '@/api/nav'
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  data() {
    return {
      active: 0,
      a: -1,
      actives: -1,   // 首页 619， 2425  ，3065 ，3860 ，4495
      scrollHeight: 0, //  应用场景637,1513,2548服务优势580 1806    关于我们   637   1194  1911                                                 
      targetHeight: 0,
      showFormBox: true,
      isShow:false
      // show:false
    }
  },
  props: ['suoyin', 'as'],
  computed: {
    ...mapState(['navList', 'showFormModal']),

  },
  created() {
    this.getNavList()
    console.log(this.suoyin, 'nav组件的索引');
    // console.log(this.active,'this.active');
    // this.active=this.actives

  },

  methods: {
    zhankai(){
      this.isShow=!this.isShow;
    },
    async getNavList() {

      const { data: res } = await navs()
      console.log(res, 'navs的数据');
    },
    ...mapActions(['showDialog']),
    showForm() {//点击立即咨询按钮
      this.showDialog(true);

    },

    getNavsList(e) {
      console.log(e.target.dataset, 'nav');

      this.active = e.target.dataset.index;
      this.a = e.target.dataset.index
      this.actives = ''
      // this.as=''
      this.$router.push({
        name: e.target.dataset.path,
        params: {
          index: e.target.dataset.index
        }
      })

      sessionStorage.setItem('i', e.target.dataset.index)
      this.showNav = true;
    },
    showBox(i) {//鼠标进入li  显示对应的navs
      this.a = i
      // this.actives=''
      // this.as=''
    },
    changeliHover(i) {//鼠标进入导航的子菜单变色
      this.actives = i
    },
    hideBox2(b) {
      if(b==0){
        this.a = -1
      }
    },
    hideBox() {
      this.a = -1
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      this.scrollHeight = scrollTop;
      // console.log('当前滚轮高度:',this.scrollHeight); // 
    },
    toArea(b, index, i) { // 这里的index是左侧导航栏传的参数，是不同区域设定好的索引值
      console.log(b.path, index, i, 'nav的e,b,index');
      // this.actives=index
      this.actives = index
      this.$emit('gotosection', index, b.path, i)
    },
    gotoHome() {
      this.$router.push('/index')
      sessionStorage.setItem('i', 0)
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    //  this.actives=this.as
    //   console.log(this.as,'this.as');
  }
}

</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

ul {
  list-style: none;
}

.container {
  background-color: #fff;
  height: 0.375rem;
  position: sticky;
  top: 0;
  z-index: 99;
  left: 0;
  box-sizing: border-box;
}

.active {
  color: #FF6A00;

  border-bottom: .0104rem solid #ff7a00;
}

.nav {
  box-sizing: border-box;
  width: 6.25rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-top: 0.3%;
}

.navBar .navs {
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  padding: 0 .026rem .04rem;
  font-size: .10rem;
  height: 100%;
  height: 32px !important;
    line-height: 32px;
  /* height: 0.375rem; */
}

.navBar .navs:nth-child(n + 2) {
  margin-left: 0.25rem;
}

.navBar li:hover{
  color: #FF6A00; 
  /* border-bottom: .0104rem solid #ff7a00; */
}
/* .active{
    color: #FF6A00;
} */
.navList {
  padding: 0.1563rem;
  background-color: #fff;
  position: absolute;
  top: 0.24rem;
  left: -0.0521rem;
  z-index: 23;
  background-color: #fff;
  padding: .1563rem;
  padding-bottom: .0521rem;
  background: #FFFFFF;
  box-shadow: 0px .0208rem .0417rem 0px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0rem .0417rem .0417rem;
}

.navsli {
  display: flex;
  margin-bottom: .1458rem;
  text-align: right;
  width: 1.45rem;
  color: #222;
  display: flex;
  align-items: center;

}

.navsli img {
  width: .0885rem;
  height: .0833rem;
  margin-right: .0521rem;
}

.navsli span {
  font-size: .0833rem;
  line-height: .1146rem;
  color: #222;
}

.moddileList{
  display: none;
}




/* 手机端适应代码 */
@media screen and (max-width: 767px) {
  .nav {
    width: 100% !important;
    height: 60px!important;
    
  }

  .nav img {
    width: 120px !important;
  }

  .navBar,
  .tel {
    display: none !important;
  }

  .menu-btn {
    display: block;
    width: 24px;
    height: 19px;
    position: relative;
    cursor: pointer;
    margin-left: 5.5rem;
  }

  .menu-btn span {
    display: block;
    width: 100%;
    height: 3px;
    top: 8px;
    position: absolute;
    left: 0;
    background-color: #625656;
  }

  .menu-btn span:first-child{
    top: 0px;
  }

  .menu-btn span:last-child {
    top: 16px;
  }

  .menu-btn.actives span:first-child {
    transform: rotate(45deg);
    top: 7px;
  }

  .menu-btn.actives span:nth-child(2) {
    opacity: 0;
  }

  .menu-btn.actives span:last-child {
    transform: rotate(-45deg);
    top: 7px;
  }



  .moddileList{
    display: none;
    position: absolute;
    top: 60px;
    background: white;
    width: 100%;
    left: 0;
    overflow: hidden;
  }

  .moddileList.in{
    display: block;
    /* height: auto!important;; */
    transition: all 3s
  }

  .moddileList li{
    font-size: 16px;
    text-align: left;
    padding: 8px 15px;
  }
 
  



}</style>